import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useState } from "react";
import ExtractCompositionToolbar from "@pages/extractCompositionPage/components/toolbar/extractCompositionToolbar";
import { Spinner } from "@components/spinner/spinner";
import { useGetCaseQuery } from "@services/api/case/caseApi";
import { useExtractDraftStatus } from "@pages/pdfviewer/component/hooks/useExtractDraftStatus";
import { ExtractContentSidebar } from "@pages/extractCompositionPage/components/contentSidebar/extractContentSidebar";
import { useGetExtractDraftQuery } from "@services/api/extractDraft/extractDraftApi";
import DragLayer from "@components/dnd/DragNDropIndicator/DragLayer/DragLayer";
import { SectionsContainer } from "../ExtractSections/SectionsContainer/SectionsContainer";
import DragPreview from "../DragPreview/DragPreview";
import { dndItemTypes } from "../ExtractSections/DndItemTypes";
import styles from "./extractComposition.module.scss";

interface ExtractCompositionProps {
  caseId: string;
  extractId: string;
}

const ExtractComposition = ({ caseId, extractId }: ExtractCompositionProps) => {
  const {
    data: extractDraft,
    isLoading: extractDraftIsLoading,
    isError: extractDraftIsError
  } = useGetExtractDraftQuery({ caseId: caseId, extractDraftId: extractId });
  const [onlySelectVerticalLines, setOnlySelectVerticalLines] = useState(false);

  const { data: caseData, isLoading: caseIdLoading } = useGetCaseQuery(caseId);

  useExtractDraftStatus(extractDraftIsLoading, extractDraftIsError);

  if (extractDraftIsLoading || caseIdLoading || !extractDraft || !caseData) {
    return <Spinner size="small" />;
  }

  return (
    <>
      <ExtractCompositionToolbar
        caseId={caseId}
        extractDraftId={extractId}
        subTitle={caseData.title}
        extractDraftTitle={extractDraft.title}
      />
      <DndProvider backend={HTML5Backend}>
        <div className={classNames("d-flex", styles.compositionContainer)}>
          <ExtractContentSidebar
            additionalClasses="h-100"
            caseId={caseId}
            setOnlySelectVerticalLines={setOnlySelectVerticalLines}
            onlySelectVerticalLines={onlySelectVerticalLines}
            extractDraft={extractDraft}
          />
          <div className="flex-grow-1">
            <SectionsContainer
              extractDraft={extractDraft}
              onlySelectVerticalLines={onlySelectVerticalLines}
            />
          </div>
        </div>
        <DragLayer
          acceptedDndTypes={[dndItemTypes.document, dndItemTypes.section]}
          render={(item, itemType) => <DragPreview item={item} itemType={itemType} />}
        />
      </DndProvider>
    </>
  );
};

export default ExtractComposition;
