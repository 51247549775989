import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useLocalization } from "@components/localization/localizationProvider";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { TableOfContentsInfoResponseModel } from "@services/api/extractDraft/models/tableOfContentsInfoResponseModel";

interface BundleSplitterProps {
  tocData: TableOfContentsInfoResponseModel;
  splitToBundlesPageCountCallback: (bundlePageCount: number | undefined) => void;
  splitToBundlesCallback: (enabled: boolean) => void;
}

const ExtractDraftSplitToBundlesFields = ({
  tocData,
  splitToBundlesPageCountCallback,
  splitToBundlesCallback
}: BundleSplitterProps) => {
  const localizer = useLocalization();

  const [splitToBundles, setSplitToBundles] = useState<boolean>(false);
  const [splitToBundlesPages, setSplitToBundlesPages] = useState<number>(500);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);

    if (value >= 0 || isNaN(value)) {
      setSplitToBundlesPages(value);
    }
  }, []);

  const minimumBundlePageCount = useMemo(() => {
    if (tocData.sections.length > 0) {
      return (
        tocData.sections
          .flatMap((x) => x.children)
          .reduce((prev, current) => (prev && prev.pageCount > current.pageCount ? prev : current))
          .pageCount +
        1 +
        tocData.pageCount
      ); //include frontpage and ToC
    }

    return 0;
  }, [tocData]);

  const bundlePageCount = useMemo(() => {
    return splitToBundlesPages < minimumBundlePageCount ||
      isNaN(splitToBundlesPages) ||
      minimumBundlePageCount === 0
      ? undefined
      : splitToBundlesPages;
  }, [minimumBundlePageCount, splitToBundlesPages]);

  useEffect(() => {
    splitToBundlesPageCountCallback(bundlePageCount);
  }, [bundlePageCount, splitToBundlesPageCountCallback]);

  useEffect(() => {
    splitToBundlesCallback(splitToBundles);
  }, [splitToBundles, splitToBundlesCallback]);

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-12 d-flex justify-content-between">
          <h4>{localizer.shouldSplitExtractToBundles()}</h4>
          <div>
            <span className="m-2">{localizer.yes()}</span>
            <InlineSwitch
              checked={splitToBundles}
              onChange={() => {
                setSplitToBundles((x) => !x);
              }}
            />
          </div>
        </div>
      </div>
      {splitToBundles && (
        <div className="row mt-2">
          <div className="col-lg-6">
            {localizer.numberOfPages()}
            <input
              className={classNames(
                "form-control",
                splitToBundlesPages < minimumBundlePageCount && "input-validation-error"
              )}
              type={"number"}
              value={splitToBundlesPages}
              onChange={onInputChange}
            />
            <span
              className={
                splitToBundlesPages < minimumBundlePageCount ? "field-validation-error" : "subtle"
              }
            >
              {localizer.extractDraftBundleInfo3()} {minimumBundlePageCount}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ExtractDraftSplitToBundlesFields;
