import { useMemo, useState } from "react";
import { useDrag } from "react-dnd";
import classNames from "classnames";
import { ReactComponent as DragIndicator } from "@content/icons/drag_indicator.svg";
import { useLocalization } from "@components/localization/localizationProvider";
import { handleDragEnd, handleDragStartRemoveGhost } from "@pages/extractCompositionPage/util/util";
import { ExtractDraftSplitterBundleModel } from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleSplitter";
import { EmblaIcon, IconSize } from "@components/embla/emblaIcon";
import styles from "./extractDraftBundleDnD.module.scss";

interface BundleDnDProps {
  bundleNumber: number;
  tableOfContentsPageCount: number;
  bundleModel: ExtractDraftSplitterBundleModel;
  deleteCallback: (bundle: ExtractDraftSplitterBundleModel) => void;
  additionalClassNames?: string;
  onChangeBundleTitle: (title: string) => void;
}

const BUNDLE_TITLE_MAX_LENGTH = 60;

const ExtractDraftBundleDnD = ({
  bundleModel,
  bundleNumber,
  deleteCallback,
  tableOfContentsPageCount,
  additionalClassNames,
  onChangeBundleTitle
}: BundleDnDProps) => {
  const localizer = useLocalization();

  const [showTitleEditInput, setShowTitleEditInput] = useState(false);
  const [titleEditInputError, setTitleEditInputError] = useState(false);
  const [bundleTitle, setBundleTitle] = useState<string | undefined>(undefined);

  const [bundleTitlePlaceholder, setBundleTitlePlaceholder] = useState(
    bundleModel.title ?? `${localizer.bundle()} ${bundleNumber}`
  );

  const isFirst = bundleNumber === 1;
  const [{ isDragging, canDrag }, drag] = useDrag(
    {
      type: "bundle",
      item: bundleModel,
      canDrag: !isFirst,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag()
      })
    },
    [bundleModel, bundleModel.extractStartIndex, bundleModel.extractEndIndex, bundleNumber]
  );

  const bundleSize = useMemo(() => {
    //bundleSize + TableOfContentsSize + frontpageSize
    return (
      bundleModel.extractEndIndex - bundleModel.extractStartIndex + 1 + tableOfContentsPageCount + 1
    );
  }, [bundleModel.extractEndIndex, bundleModel.extractStartIndex, tableOfContentsPageCount]);

  const saveNewTitle = () => {
    if (bundleTitle && bundleTitle.length > BUNDLE_TITLE_MAX_LENGTH) {
      setTitleEditInputError(true);
      return;
    }

    if (bundleTitle) {
      onChangeBundleTitle(bundleTitle);
    }

    setShowTitleEditInput(false);
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      saveNewTitle();
    }
  };

  return (
    <div
      className={classNames(
        "d-flex justify-content-between align-content-center text-center",
        styles.container,
        additionalClassNames && additionalClassNames
      )}
      role={isFirst ? undefined : "button"}
      ref={drag}
      style={{ opacity: isDragging ? 0.99 : 1 }} //Hack to remove drag preview
      onDragStart={(e) => {
        canDrag && handleDragStartRemoveGhost(e);
      }} //Hack to remove drag preview
      onDragEnd={handleDragEnd} //Hack to remove drag preview
    >
      <div onClick={() => setShowTitleEditInput(true)} className="d-flex align-items-center">
        {showTitleEditInput ? (
          <div>
            <input
              className="form-control"
              autoFocus
              onBlur={saveNewTitle}
              value={bundleTitle}
              placeholder={localizer.bundleNamePlaceholder()}
              onChange={(e) => setBundleTitle(e.target.value)}
              onKeyDown={submitOnEnter}
            />
            {titleEditInputError && (
              <span className={styles.warningText}>{localizer.maxLength60()}</span>
            )}
          </div>
        ) : (
          <>
            {!isFirst && <DragIndicator className="mx-2" />}
            <p className="m-0">
              {bundleTitlePlaceholder} {bundleTitle} ({bundleSize} {localizer.pages()})
            </p>
            <EmblaIcon size={IconSize.Medium} iconName="edit" additionalClasses="ml-1" />
          </>
        )}
      </div>
      {!isFirst && (
        <div onClick={() => deleteCallback(bundleModel)}>
          <EmblaIcon iconName={"delete"} />
        </div>
      )}
    </div>
  );
};

export default ExtractDraftBundleDnD;
