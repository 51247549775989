import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { hearingBundleTitleRegex } from "@services/api/util/validationRegex";

export type UpdateCaseExtractDraftModel = {
  title: string;
};

export const updateExtractDraftValidation = (localizer: Localizer) =>
  createValidationSchema<UpdateCaseExtractDraftModel>({
    title: yup
      .string()
      .required(localizer.titleRequired())
      .matches(hearingBundleTitleRegex, localizer.noSpecialCharactersAllowed())
  });
