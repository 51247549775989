import { v4 as uuid } from "uuid";
import {
  TableOfContentItemModel,
  TableOfContentSectionModel
} from "@services/api/extractDraft/models/tableOfContentSectionModel";
import { TableOfContentsInfoResponseModel } from "@services/api/extractDraft/models/tableOfContentsInfoResponseModel";
import {
  ExtractDraftBundle,
  ExtractDraftBundleModel
} from "@services/api/case/models/exportExtractDraftModel";
import { ExtractDraftSplitterBundleModel } from "./extractDraftBundleSplitter";

export const calculateBundleEnding = (
  startIndex: number,
  bundles: ExtractDraftSplitterBundleModel[],
  tocData: TableOfContentSectionModel[]
) => {
  const nextBundle = bundles.find((b) => b.extractStartIndex > startIndex);
  const flatTocDocuments = tocData
    .flatMap((b) => b.children)
    .sort((a, b) => a.pageNumber - b.pageNumber);

  if (nextBundle) {
    const nextBundleDocumentIndex = flatTocDocuments.findIndex(
      (b) => b.extractDraftDocumentId === nextBundle.firstExtractDraftDocumentId
    );

    if (nextBundleDocumentIndex === -1) {
      throw new Error();
    }

    const lastBundleItem = flatTocDocuments[nextBundleDocumentIndex - 1];

    return {
      endIndex: nextBundle.extractStartIndex - 1,
      endExtractDocumentId: lastBundleItem.extractDraftDocumentId
    };
  } else if (tocData) {
    const lastBundleItem = calculateLastBundleItem(tocData);

    return {
      endIndex: lastBundleItem.pageNumber - 1 + lastBundleItem.pageCount - 1,
      endExtractDocumentId: lastBundleItem.extractDraftDocumentId
    };
  } else {
    //shouldn't happen

    throw new Error();
  }
};

export const hasBundlesChanged = (
  oldBundles: ExtractDraftSplitterBundleModel[],
  newBundles: ExtractDraftSplitterBundleModel[]
) => {
  for (const b of newBundles) {
    const oldBundle = oldBundles.find((x) => x.id === b.id);

    const hasChanged =
      oldBundle?.extractStartIndex !== b.extractStartIndex ||
      oldBundle?.extractEndIndex !== b.extractEndIndex ||
      oldBundle?.firstExtractDraftDocumentId !== b.firstExtractDraftDocumentId ||
      oldBundle?.lastExtractDraftDocumentId !== b.lastExtractDraftDocumentId;

    if (hasChanged) {
      return true;
    }
  }

  return false;
};

export const recalibrateBundles = (
  bundlesToCalibrate: ExtractDraftSplitterBundleModel[],
  tocData: TableOfContentSectionModel[]
) => {
  const newBundles = [...bundlesToCalibrate];
  const flatTocData = tocData
    .flatMap((b) => b.children)
    .sort((a, b) => a.pageNumber - b.pageNumber);

  const calibratedBundles = newBundles.map((b) => {
    const bundleDocumentStart = flatTocData.find(
      (x) => x.extractDraftDocumentId === b.firstExtractDraftDocumentId
    );

    if (!bundleDocumentStart) throw new Error();

    const bundleEnding = calculateBundleEnding(b.extractStartIndex, newBundles, tocData ?? []);
    return {
      ...b,
      extractEndIndex: bundleEnding.endIndex,
      lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId,
      extractStartIndex: bundleDocumentStart?.pageNumber - 1
    };
  });

  return hasBundlesChanged(bundlesToCalibrate, calibratedBundles)
    ? calibratedBundles
    : bundlesToCalibrate;
};

const findFirstNotEmptySection = (sections: TableOfContentSectionModel[]) => {
  return sections.find((s) => s.children.length > 0);
};

export const generateInitialBundles = (
  tocDataResponse: TableOfContentsInfoResponseModel,
  initialBundlePageCount: number
) => {
  const initialBundles: ExtractDraftSplitterBundleModel[] = [];
  const firstNotEmptySection = findFirstNotEmptySection(tocDataResponse.sections);
  //Add top bundle
  const initialBundleEnding = calculateBundleEnding(
    tocDataResponse.sections[0].pageNumber - 1,
    initialBundles,
    tocDataResponse.sections
  );
  initialBundles.push({
    extractStartIndex: tocDataResponse.sections[0].pageNumber - 1,
    extractEndIndex: initialBundleEnding.endIndex,
    id: uuid(),
    firstExtractDraftDocumentId: firstNotEmptySection?.children[0].extractDraftDocumentId ?? "",
    lastExtractDraftDocumentId: initialBundleEnding.endExtractDocumentId
  });

  const flatTocDocuments = tocDataResponse.sections
    .flatMap((b) => b.children)
    .sort((a, b) => a.pageNumber - b.pageNumber);
  const lastTocChild = flatTocDocuments[flatTocDocuments.length - 1];
  if (initialBundlePageCount < lastTocChild.pageNumber) {
    let bundleCounter = 1 + tocDataResponse.pageCount;
    for (const tocDocument of flatTocDocuments) {
      bundleCounter += tocDocument.pageCount;

      if (bundleCounter >= initialBundlePageCount) {
        bundleCounter = 1 + tocDataResponse.pageCount + tocDocument.pageCount;

        const bundleEnding = calculateBundleEnding(
          tocDocument.pageNumber - 1,
          initialBundles,
          tocDataResponse.sections
        );
        initialBundles.push({
          extractStartIndex: tocDocument.pageNumber - 1,
          extractEndIndex: bundleEnding.endIndex,
          id: uuid(),
          firstExtractDraftDocumentId: tocDocument.extractDraftDocumentId,
          lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId
        });
      }
    }
  }

  return recalibrateBundles(initialBundles, tocDataResponse.sections);
};

export const findBundleIndex = (
  bundleItem: TableOfContentItemModel,
  bundles: ExtractDraftSplitterBundleModel[]
) => {
  return bundles.findIndex(
    (b) => b?.firstExtractDraftDocumentId === bundleItem?.extractDraftDocumentId
  );
};

export const calculateLastBundleItem = (tocData: TableOfContentSectionModel[]) => {
  const flatBundleItems = tocData.flatMap((b) => b.children);
  const lastBundleItem = flatBundleItems.reduce((prev, current) =>
    prev && prev.pageNumber > current.pageNumber ? prev : current
  );

  return lastBundleItem;
};

type GetBundlesProps = {
  tableOfContentsData?: TableOfContentsInfoResponseModel;
  splitToBundles: boolean;
  bundles: ExtractDraftBundle[];
};

export const getBundlesForSending = ({
  tableOfContentsData,
  splitToBundles,
  bundles
}: GetBundlesProps) => {
  const allSectionDocuments =
    tableOfContentsData?.sections.map((section) => section.children).flat() ?? [];
  let serializedBundles = [];
  if (!splitToBundles) {
    const allSectionCaseDocumentIds = allSectionDocuments.map((child) => child.caseDocumentId);
    serializedBundles = [
      {
        title: "Extract",
        caseDocumentIds: allSectionCaseDocumentIds
      }
    ];
  } else {
    // Get the caseDocumentIds between the first and last extractDraftDocumentId in each bundle
    serializedBundles = getSerializedBundles(allSectionDocuments, bundles);
  }

  return serializedBundles;
};

const getSerializedBundles = (
  sectionsDocuments: TableOfContentItemModel[],
  bundles: ExtractDraftBundle[]
) => {
  const allSectionCaseDocumentIds = sectionsDocuments.map((document) => document.caseDocumentId);

  const serializedBundles: ExtractDraftBundleModel[] = bundles.map((bun, index) => {
    const startIndex = sectionsDocuments.findIndex(
      (document) => document.extractDraftDocumentId === bun.firstExtractDraftDocumentId
    );
    const endIndex = sectionsDocuments.findIndex(
      (document) => document.extractDraftDocumentId === bun.lastExtractDraftDocumentId
    );
    const caseDocumentIdsInBundle = allSectionCaseDocumentIds.slice(startIndex, endIndex + 1);

    return {
      title: bun.title,
      caseDocumentIds: caseDocumentIdsInBundle
    };
  });

  return serializedBundles;
};
