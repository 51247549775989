import classNames from "classnames";
import { useState } from "react";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { EmblaIcon, IconColor, IconSize } from "@components/embla/emblaIcon";
import { ExtractDraftSectionDocumentModel } from "@services/api/extractDraft/models/extractDraftSectionDocumentModel";
import { useLocalization } from "@components/localization/localizationProvider";
import useDateFormatter from "src/hooks/useDateFormatter";
import { useDragDropSectionDocumentReorder } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { DocumentSortType } from "@services/api/extractDraft/models/extractDraftSectionModel";
import ModalMessage from "@components/modal/modalMessage";
import { handleDragEnd, handleDragStartRemoveGhost } from "@pages/extractCompositionPage/util/util";
import { IconButton } from "@components/button/IconButton/IconButton";
import { useDeleteDocumentFromSectionExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import IndicatorLine from "@components/dnd/DragNDropIndicator/IndicatorLine/IndicatorLine";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";
import { IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import styles from "./Document.module.scss";
import EditDocumentModal from "./DocumentModal/EditDocumentModal";

type DocumentProps = {
  document: ExtractDraftSectionDocumentModel;
  caseId: string;
  extractDraftId: string;
  sectionId: string;
  sortType: DocumentSortType;
  index: number;
  onlySelectVerticalLines: boolean;
  sameSectionDocumentHover: boolean;
};

const Document = ({
  document,
  caseId,
  extractDraftId,
  sectionId,
  sortType,
  index,
  onlySelectVerticalLines,
  sameSectionDocumentHover
}: DocumentProps) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dateFormatter = useDateFormatter();
  const localizer = useLocalization();

  const [removeDocument, removeRequest] = useDeleteDocumentFromSectionExtractDraftMutation();

  const { divRef, position, moveDocument, setShowWarningModal, showWarningModal, isDragging } =
    useDragDropSectionDocumentReorder({
      documentData: document,
      sectionId,
      extractDraftId,
      caseId,
      index,
      onlySelectVerticalLines,
      documentSortType: sortType
    });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleRemoveFromDraft = () => {
    removeDocument({
      caseId,
      extractDraftId,
      sectionId,
      documentId: document.id,
      shouldInvalidate: false
    });
  };

  return (
    <>
      <IndicatorLine
        isVisible={
          position === "above" && (sortType === DocumentSortType.Custom || sameSectionDocumentHover)
        }
      />
      <div
        onDragStart={handleDragStartRemoveGhost}
        onDragEnd={handleDragEnd}
        ref={divRef}
        onClick={handleOpenModal}
        onMouseOver={() => setShowDeleteButton(true)}
        onMouseLeave={() => setShowDeleteButton(false)}
        className={classNames(
          "grid px-4",
          styles.container,
          document.intervalExtractionType === IntervalExtractionType.NoDocuments && styles.opaque
        )}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <div className="row">
          <div className="d-flex gap-8 align-items-center col-3">
            <EmblaIcon containerSize={IconSize.Large} color={IconColor.Gray} iconName="document" />
            <p
              title={document.caseDocumentName}
              className={classNames(styles.caseDocumentName, "ellipsisContainer mb-0")}
            >
              {document.caseDocumentName}
            </p>
          </div>
          <div className={classNames(styles.textStart, "col-2 d-flex align-items-center")}>
            {document.caseDocumentNumber ? (
              document.caseDocumentNumber
            ) : (
              <div className={classNames("d-flex align-items-center gap-2 ellipsisContainer")}>
                <div>
                  <EmblaIcon size={IconSize.Large} color={IconColor.Warning} iconName="warning" />
                </div>
                <p title={localizer.appendixNumberMissing()} className="ellipsisContainer mb-0">
                  {localizer.appendixNumberMissing()}
                </p>
              </div>
            )}
          </div>
          <p
            className={classNames(
              styles.textStart,
              "col-3 d-flex align-items-center ellipsisContainer mb-0"
            )}
          >
            {document.intervalExtractionType === IntervalExtractionType.NoDocuments
              ? localizer.leftOut()
              : document.pageIntervals
                  .map((interval) => `${interval.firstPageIndex + 1}-${interval.lastPageIndex + 1}`)
                  .join(", ")}
          </p>
          <div className={classNames(styles.textStart, "col-2 d-flex align-items-center")}>
            {document.caseDocumentDate || document.caseDocumentDateType === DateTypeEnum.UnDated ? (
              document.caseDocumentDateType === DateTypeEnum.UnDated ? (
                localizer.noDateDateType()
              ) : (
                dateFormatter.date(
                  document.caseDocumentDate,
                  undefined,
                  document.caseDocumentDateType
                )
              )
            ) : (
              <div className={classNames("d-flex gap-2 ellipsisContainer")}>
                <div className={classNames("d-flex align-items-center gap-2 ellipsisContainer")}>
                  <div>
                    <EmblaIcon iconName="warning" color={IconColor.Warning} size={IconSize.Large} />
                  </div>
                  <span title={localizer.dateMissing()} className="ellipsisContainer">
                    {localizer.dateMissing()}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={classNames(styles.textStart, "col-2 d-flex justify-content-between")}>
            <p className={styles.ellipsisContainer} title={localizer.yes()}>
              {document.includeMarkings ? localizer.yes() : localizer.no()}
            </p>
            <div className={classNames("d-flex align-items-center", styles.editIconContainer)}>
              {showDeleteButton && (
                <IconButton
                  disabled={removeRequest.status === QueryStatus.pending}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveFromDraft();
                  }}
                  iconName="delete"
                  size={IconSize.Large}
                  color={IconColor.Gray}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <IndicatorLine
        isVisible={
          position === "below" && (sortType === DocumentSortType.Custom || sameSectionDocumentHover)
        }
      />
      <EditDocumentModal
        extractDraftId={extractDraftId}
        caseId={caseId}
        document={document}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sectionId={sectionId}
      />
      <ModalMessage
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        title={localizer.changeDocumentSorting()}
        description={localizer.changeDocumentSortingWarning()}
        acceptButtonText={localizer.removeSorting()}
        cancelButtonText={localizer.cancel()}
        cancelAction={() => setShowWarningModal(false)}
        acceptAction={moveDocument}
        modalAcceptType={"primary"}
      />
    </>
  );
};

export default Document;
