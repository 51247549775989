import { useState } from "react";
import ReactDOM from "react-dom";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import {
  useExportExtractDraftMutation,
  useGetTableOfContentsInfoExtractDraftQuery
} from "@services/api/extractDraft/extractDraftApi";
import { ExportExtractDraftForm } from "@components/Export/exportExtractDraftForm";
import {
  ExportExtractDraftModel,
  ExtractDraftBundle
} from "@services/api/case/models/exportExtractDraftModel";
import { useGetCaseParticipantsQuery } from "@services/api/participant/caseParticipantApi";
import { DownloadExtractDraftExport } from "@pages/extractCompositionPage/components/export/downloadExtractDraftExport";
import ExtractDraftSplitToBundlesFields from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftSplitToBundlesFields";
import ExtractDraftBundleSplitter from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleSplitter";
import { getBundlesForSending } from "../bundleSplitter/bundleUtils";

interface ExportExtractDraftProps {
  caseId: string;
  extractId: string;
  submitPortal: Element;
  headerPortal: Element;
}

const ExportExtractDraft = ({
  caseId,
  extractId,
  submitPortal,
  headerPortal
}: ExportExtractDraftProps) => {
  const localizer = useLocalization();
  const [exportId, setExportId] = useState<string | undefined>();
  const formFieldIdPrepend = "export-extractDraft-";

  const { data: participants, isLoading: participantsIsLoading } = useGetCaseParticipantsQuery({
    caseId
  });
  const [exportExtractDraft, exportExtractDraftRequest] = useExportExtractDraftMutation();

  const { data: tocData } = useGetTableOfContentsInfoExtractDraftQuery({
    caseId,
    extractDraftId: extractId
  });

  const [splitToBundles, setSplitToBundles] = useState(true);
  const [splitToBundlesPages, setSplitToBundlesPages] = useState<number | undefined>(undefined);
  const [bundles, setBundles] = useState<ExtractDraftBundle[]>([]);
  const [showBundleSplitter, setShowBundleSplitter] = useState<boolean>(false);

  const initialValues: ExportExtractDraftModel = {
    markingOwners: [],
    markingTypes: [
      MarkingType.Comment,
      MarkingType.Highlight,
      MarkingType.VerticalLines,
      MarkingType.FocusBox
    ],
    convertLinesToBlack: true,
    bundles: []
  };

  const methods = useValidatedForm({
    defaultValues: initialValues
  });

  const onSubmitExportExtractDraft = async (model: ExportExtractDraftModel) => {
    model.bundles = getBundlesForSending({ bundles, tableOfContentsData: tocData, splitToBundles });

    const result = await exportExtractDraft({ caseId, extractDraftId: extractId, model }).unwrap();
    if (result) {
      setExportId(result);
    }
  };

  return (
    <>
      {exportId ? (
        <DownloadExtractDraftExport
          caseId={caseId}
          extractId={extractId}
          extractExportId={exportId}
        />
      ) : (
        <>
          {showBundleSplitter && tocData && splitToBundlesPages ? (
            <ExtractDraftBundleSplitter
              initialBundlePageCount={splitToBundlesPages}
              bundleChangeCallback={setBundles}
              caseId={caseId}
              extractId={extractId}
            />
          ) : (
            <>
              <div className="subtle padding-top-l">
                <p>{localizer.exportExtractDraftHint1()}</p>
                <p>{localizer.exportPresentationHint2()}</p>
              </div>
              <ExportExtractDraftForm
                methods={methods}
                fieldIdPrepend={formFieldIdPrepend}
                participants={participants}
                onSubmitCallback={() => {}}
              />
              {tocData && (
                <ExtractDraftSplitToBundlesFields
                  tocData={tocData}
                  splitToBundlesPageCountCallback={(x) => setSplitToBundlesPages(x)}
                  splitToBundlesCallback={(x) => setSplitToBundles(x)}
                />
              )}
            </>
          )}

          {ReactDOM.createPortal(
            splitToBundles && !showBundleSplitter ? (
              <FormSubmitButton
                state={{
                  isSubmitting: exportExtractDraftRequest.isLoading,
                  isSubmitSuccessful: exportExtractDraftRequest.isSuccess
                }}
                isPrimary={true}
                onClick={() => setShowBundleSplitter(true)}
                disabled={participantsIsLoading || !splitToBundlesPages}
              >
                {localizer.next()}
              </FormSubmitButton>
            ) : (
              <FormSubmitButton
                state={{
                  isSubmitting: exportExtractDraftRequest.isLoading,
                  isSubmitSuccessful: exportExtractDraftRequest.isSuccess
                }}
                isPrimary={true}
                onClick={methods.handleSubmit(onSubmitExportExtractDraft)}
                disabled={participantsIsLoading}
              >
                {localizer.exportVerb()}
              </FormSubmitButton>
            ),
            submitPortal
          )}

          {ReactDOM.createPortal(localizer.exportAsPdf(), headerPortal)}
        </>
      )}
    </>
  );
};

export default ExportExtractDraft;
