import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { CreateCaseExtractDraftModel } from "@services/api/extractDraft/models/createCaseExtractDraftModel";
import { LengthValidations } from "src/utility/constants";
import { hearingBundleTitleRegex } from "@services/api/util/validationRegex";

export const extractDraftValidation = (localizer: Localizer) =>
  createValidationSchema<CreateCaseExtractDraftModel>({
    title: yup
      .string()
      .max(LengthValidations.Length150, localizer.maxLength150())
      .matches(hearingBundleTitleRegex, localizer.noSpecialCharactersAllowed())
      .required(localizer.titleRequired()),
    language: yup.number().required()
  });
